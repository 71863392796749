import React, { useEffect, useRef, useState } from "react";
import { onAuthStart } from "@/Services/Auth";
import { requestOTP, setupRegisterEmail, signin } from "@/Services/User";
import { Composition } from "atomic-layout";
// import background from "@/Assets/sign_up_banner.png";
import background from "@/Assets/get-started.png";
import enter_otp from "@/Assets/enter_otp.png";
import signInButton from "@/Assets/btn_google_signin_dark_normal_web.png";
import Layout from "antd/lib/layout/layout";
import logoLight from "@/Assets/logo-light.png";
import { Button, Divider, Form, Input, notification } from "antd";
import { CheckCircleTwoTone, MailOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import CountDownTimer from "./Common/Timer";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { authStatus } from "@/Models/Selectors";
import { isValidEmail } from "./Common/Utils";
import {
  ENABLE_GOOGLE_AUTH_IN_ZOOM,
  ENABLE_GOOGLE_AUTH_OUTSIDE_ZOOM,
  isGuestModeSupported,
  OTP_RESEND_SECONDS,
} from "@/Services/Constants";
import Loader from "./Loader";
import { isRunningAsZoomApp } from "@/Services/Constants";
import GuestSignUpForm from "./GuestSignup";
import {
  getDocument,
  getFirebaseAuth,
  getSingleDocument,
  isUserRegistered,
} from "@/Services/Firebase";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { setUser } from "@sentry/react";

interface RouteParams {
  email: string;
  password: string;
  name: string;
  classcode: string;
}

interface LoginComponent extends RouteComponentProps<RouteParams> {}

const LoginAction = ({
  presetEmail,
  presetPassword,
  presetName,
  presetClasscode,
}: {
  presetEmail?: string;
  presetPassword?: string;
  presetName?: string;
  presetClasscode?: string;
}) => {
  const history = useHistory();
  // const presetEmail = props.match.params.email;
  // const presetPassword = props.match.params.password;
  const [email, setEmail] = useState(presetEmail || "");
  const [otp, setOtp] = useState(presetPassword || "");
  const [name, setName] = useState(presetName || "");
  const [classcode, setClasscode] = useState(presetClasscode || "");
  const [password, setPassword] = useState("");
  let passwordRef = useRef();

  const userAuthstatus = useSelector(authStatus);
  const [loginState, setLoginState] = useState<
    | "enter_email"
    | "sending_email"
    | "enter_otp"
    | "sending_otp"
    | "sign_up"
    | "enter_password"
  >("enter_email");
  const [resendOtp, setResendOtp] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [userExists, setUserExists] = useState(false);
  let firebaseUserExists = false;

  useEffect(() => {
    if (presetEmail && presetPassword) {
      generateOTP()?.then(() => {
        sendOTP(presetPassword);
      });
    }
  }, []);

  const checkUserExists = async () => {
    if (await isUserRegistered(email)) {
      setUserExists(true);
      return true;
    }

    return false;
  };

  const firebaseProceed = async () => {
    try {
      const res = await userExistsOnFirebase();
      if (res.length > 0) {
        setUserExists(true);
        firebaseUserExists = true;
      }
    } catch (error) {}

    if (!firebaseUserExists) {
      const updatedLoginState = userExists ? "enter_password" : "sign_up";

      setupRegisterEmail(email);
      history.push("/signup");

      setLoginState(updatedLoginState);
    }
  };

  const userExistsOnFirebase = async () => {
    return await fetchSignInMethodsForEmail(getFirebaseAuth(), email);
  };

  const firebaseLoginWithEmailPassword = async () => {
    const authProvider = EmailAuthProvider.credential(email, password);
    //const response = await signInWithCredential(getFirebaseAuth(), authProvider);
    try {
      console.log("Started auth");
      const data = await signInWithEmailAndPassword(
        getFirebaseAuth(),
        email,
        password
      );
      console.log("Started auth 1");
      const accessToken = await data.user.getIdToken();
      const { uid, refreshToken } = data.user;
      console.log("Started auth sign in");
      signin(email, accessToken, "custom")
        .then(() => {
          console.log("OTP SENT SUCCESSFULLY", data.user);
          history.push("/");
        })
        .catch((error) => {
          const msg = "OTP Verification Failed. Try again";
          console.log("Catch response ", error);
          setError(msg);
          setOtp("");
          setLoginState("enter_otp");
        });
      console.log(await data.user.getIdToken());
    } catch (e) {
      setError("Incorrent email / password");
    }
  };

  const generateOTP = async () => {
    setError(null);
    if (isValidEmail(email)) {
      console.log("email is ", email);
      console.log("generating OTP");
      setLoginState("sending_email");
      return requestOTP(email).then((response) => {
        const { is_user_exists, code, message, title } = response;
        if (code == 0) {
          const updatedLoginState = is_user_exists
            ? "enter_password"
            : "sign_up";
          if (!is_user_exists) {
            history.push("/signup");
          }
          setLoginState(updatedLoginState);
        } else {
          notification.warning({
            message,
          });
        }
      });
    } else {
      setError("Invalid Email Address");
    }
  };

  const resendOTPClick = () => {
    //resendOtp
    setLoginState("enter_otp");
    setResendOtp(false);
    setError(null);
    setOtp("");
    requestOTP(email).then(() => {
      notification.success({
        message: "OTP Resent",
        description: (
          <span>
            OTP resent on your email id <b>{email}</b>. Please check your Inbox
            (including the spam folder)
          </span>
        ),
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
    });
  };

  const changeEmailClick = () => {
    //changeEmailLogic
    setLoginState("enter_email");
    setResendOtp(false);
    setError(null);
    setOtp("");
  };

  const signupClicked = () => {
    history.push("/signup");
  };

  const sendOTP = (code: string) => {
    setError(null);
    if (code.length == 4) {
      console.log("submitting OTP");
      setLoginState("sending_otp");
      signin(email, code, "custom")
        .then(() => {
          console.log("OTP SENT SUCCESSFULLY");
          history.push("/");
        })
        .catch((error) => {
          const { response } = error;
          const msg =
            response.data.message || "OTP Verification Failed. Try again";
          setError(msg);
          setOtp("");
          setLoginState("enter_otp");
        });
      // submitOTP(email, otp, () => {
      //   console.log("OTP SENT SUCCESSFULLY");
      //   setError("Invalid OTP");
      //   setLoginState("enter_otp")
      //   return history.push("/signup")
      //   setTimeout(() => {
      //     setResendOtp(true);
      //   }, 5000)
      // })
    }
  };

  return (
    <>
      {loginState === "enter_email" ||
      loginState === "sending_email" ||
      loginState === "sign_up" ? (
        <div>
          <div style={{ width: "20em" }} className="ml-auto mr-auto mt-12 mb-4">
            <div className="text-left text-sm text-gray-600">Email</div>
            <Input
              size="large"
              type="text"
              autoComplete="email"
              name="email"
              allowClear
              className="border-red"
              value={email}
              placeholder="Enter your email id"
              onChange={(e) => {
                setError(null);
                setEmail(e.target.value.trim());
                setLoginState("enter_email");
              }}
              onError={(e) => console.log(e)}
              onPressEnter={() => {
                userExists
                  ? firebaseLoginWithEmailPassword()
                  : firebaseProceed();
              }}
              // prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </div>
          {userExists && (
            <div
              style={{ width: "20em" }}
              className="ml-auto mr-auto mt-12 mb-4"
            >
              <div className="text-left text-sm text-gray-600">Password</div>
              <Input
                size="large"
                type="password"
                autoComplete="password"
                name="password"
                allowClear
                className="border-red"
                value={passwordRef.current}
                placeholder="Enter Password"
                onChange={(e) => {
                  setError(null);
                  setPassword(e.target.value.trim());
                }}
                onError={(e) => console.log(e)}
                ref={passwordRef.current}
              />
            </div>
          )}
          <span className="text-red-600">{error}</span>

          {loginState == "sign_up" ? (
            <>
              <span className="text-gray-600">
                {"We sense a new user! Sign up OTP sent"}
              </span>
              <div style={{ width: "13em" }} className="ml-auto mr-auto mt-12">
                <Button
                  size="large"
                  className=""
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={() => signupClicked()}
                >
                  Sign Up!
                </Button>
              </div>
            </>
          ) : (
            <div style={{ width: "13em" }} className="ml-auto mr-auto mt-12">
              <Button
                loading={loginState === "sending_email"}
                size="large"
                className=""
                type="primary"
                style={{ width: "100%" }}
                onClick={() =>
                  userExists
                    ? firebaseLoginWithEmailPassword()
                    : firebaseProceed()
                }
              >
                Proceed
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="ml-auto mr-auto" style={{ width: "25em" }}>
            <img src={enter_otp} alt="login page background" />
          </div>
          <div className="mt-8">
            OTP sent to <span>{email}</span>
            <Button type="link" onClick={() => changeEmailClick()}>
              (change)
            </Button>
          </div>
          <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
  }}
>
  {Array.from({ length: 4 }).map((_, index) => (
    <React.Fragment key={index}>
      <input
        type="text"
        style={{
          width: '3em',
          height: '4em',
          margin: '0 0.5em',
          opacity: loginState === 'sending_otp' ? 0.5 : 1,
          pointerEvents: loginState === 'sending_otp' ? 'none' : 'auto',
          textAlign: 'center', // Center the text in the input
        }}
        value={otp[index] || ''} // Use the index to get the corresponding digit
        onChange={(e) => {
          const val = e.target.value;
          setError(null);
          if (/^\d*$/.test(val)) {
            const newOtp = otp.split('');
            newOtp[index] = val; // Update the specific index
            setOtp(newOtp.join(''));
            if (newOtp.join('').length === 4) sendOTP(newOtp.join(''));
          }
        }}
        maxLength={1} // Allow only one character in each input
      />
      {index < 3 && <span>-</span>} {/* Render separator except after the last input */}
    </React.Fragment>
  ))}
</div>

          <div>
          {/* <OtpInput
          shouldAutoFocus={true}
  containerStyle={{
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    marginTop: "1em",
  }}
  inputStyle={{
    width: "3em",
    height: "4em",
    margin: "0 1em",
    opacity: loginState === "sending_otp" ? 0.5 : 1,
    pointerEvents: loginState === "sending_otp" ? "none" : "auto",
  }}
  value={otp}
  onChange={(val: string) => {
    setError(null);
    // Only allow numeric input if desired
    if (/^\d*$/.test(val)) {
      setOtp(val);
      if (val.length === 4) sendOTP(val);
    }
  }}
  numInputs={4}
          separator={<span>-</span>}
          /> */}



            <div className="text-red-600 mt-4">{error}</div>
          </div>
          {
            <div className="mt-4">
              Didn't get the code?
              <span>
                {resendOtp ? (
                  <Button type="link" onClick={() => resendOTPClick()}>
                    Resend OTP
                  </Button>
                ) : (
                  <span>
                    {" "}
                    Resend OTP in{" "}
                    <CountDownTimer
                      seconds={OTP_RESEND_SECONDS}
                      onComplete={() => setResendOtp(true)}
                    />{" "}
                  </span>
                )}
              </span>
            </div>
          }
          <div style={{ width: "20em" }} className="ml-auto mr-auto mt-12">
            <Button
              style={{ width: "13em" }}
              loading={loginState === "sending_otp"}
              size="large"
              className=""
              type="primary"
              onClick={() => sendOTP(otp)}
            >
              Login
            </Button>
          </div>
        </div>
      )}
      {(isRunningAsZoomApp && !ENABLE_GOOGLE_AUTH_IN_ZOOM) ||
      !ENABLE_GOOGLE_AUTH_OUTSIDE_ZOOM ? null : (
        <>
          <Divider style={{ marginTop: "2em" }}>
            {" "}
            <span className="text-xs text-gray-600"> Login in via Google </span>
          </Divider>

          <div className="mt-12" onClick={onAuthStart}>
            <button>
              <img
                style={{ width: "13em" }}
                // className="w-1/2"
                src={signInButton}
                alt="sign in button"
              />
            </button>
          </div>
        </>
      )}

      {isGuestModeSupported ? (
        <>
          <Divider style={{ marginTop: "2em" }}>
            {" "}
            <span className="text-xs text-gray-600"> OR </span>
          </Divider>

          <GuestSignUpForm
            presetClasscode={presetClasscode}
            presetName={presetName}
          />
        </>
      ) : null}
    </>
  );
};

const LoginPage: React.FC<LoginComponent> = function (props) {
  const status = useSelector(authStatus);

  const presetEmail = props.match.params.email || "";
  const presetPassword = props.match.params.password || "";
  const setName = props.match.params.name || "";
  const setClasscode = props.match.params.classcode || "";

  const signUpClicked = () => {
    console.log("Signup Clicked");
  };

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  return (
    <Composition
      templateCols="1fr"
      templateRows="1fr"
      templateColsMd="2fr 1.5fr"
      templateRowsMd="200px 1fr"
      height="calc(100vh - 123px)"
      areas={`
      mobileAction
      `}
      areasMd={`banner action`}
    >
      {({ Banner, Action, MobileAction }) => (
        <>
          {/* <Composition col="1/1" colMd="1/3">
        <h1 className="mx-auto p-8 text-6xl">Welcome to Vidya</h1>
      </Composition> */}
          <Banner>
            <div className="lg:w-1/2 md:w-full ml-auto mr-auto">
              <img src={background} alt="login page background" />
            </div>

            <div className="font-light mt-8 text-center text-black">
              <img className="lg:w-1/4 md:w-1/2" src={logoLight} />
            </div>

            <div className="font-light mt-8 text-center text-black text-3xl">
              Making Classrooms Engaging
            </div>
          </Banner>
          <Action>
            <Layout
              style={{
                minHeight: "calc(100vh - 123px)",
                // background: "#2d3748",
                // background: "#1a202c"
                background: "#e7e7e7",
              }}
            >
              {/* <Composition
                templateRows="100px 100px 1fr"
                justifyItems="center"
                className="md:mt-16 md:p-6"
              > */}
              <div className="h-full lg:mt-12 md:mt-12 md:pt-16 mt-12">
                <div className="font-light text-center text-3xl">
                  Sign-Up / Sign-In
                </div>
                <LoginAction
                  presetEmail={presetEmail}
                  presetPassword={presetPassword}
                  presetName={setName}
                  presetClasscode={setClasscode}
                />
                <div className=" mt-8 space-x-10 text-white">
                  <span>
                    <a
                      className=""
                      target={"_blank"}
                      href="https://vdy.app/privacy"
                    >
                      Privacy Policy{" "}
                    </a>{" "}
                  </span>
                  {/* <span>|  </span> */}
                  <span>
                    <a
                      className=""
                      target={"_blank"}
                      href="https://vdy.app/tnc"
                    >
                      Terms & Conditions{" "}
                    </a>
                  </span>
                </div>
                <div className="mt-2">
                  {status === "authenticating" ? <Loader /> : null}
                </div>
              </div>

              {/* </Composition> */}
            </Layout>
          </Action>
          <MobileAction>
            <Layout
              style={{
                minHeight: "calc(100vh + 24px)",
                zIndex: 10000,
                marginTop: "-64px",
                position: "absolute",
                width: "100%",
                // background: "#2d3748",
                // background: "#1a202c"
                background: "#e7e7e7",
              }}
            >
              {/* <Composition
                templateRows="100px 100px 1fr"
                justifyItems="center"
                className="md:mt-16 md:p-6"
              > */}
              <div className="h-full lg:mt-64 lg:pt-32 md:mt-0 md:pt-16 mt-32">
                <div className="font-light text-center">
                  <img src={logoLight} className="ml-auto, mr-auto w-2/3" />
                </div>

                <div className="font-light mt-8 ml-4 mr-4 text-center text-2xl">
                  The Best Tool for Classrooms Engagement
                </div>
                <div className="font-light mt-8 text-center text-xl">
                  Get Started
                </div>
                <LoginAction
                  presetEmail={presetEmail}
                  presetPassword={presetPassword}
                  presetName={setName}
                  presetClasscode={setClasscode}
                />
                <div className="mt-8 space-x-10 text-white">
                  <span>
                    <a className="" href="#">
                      Privacy Policy{" "}
                    </a>{" "}
                  </span>
                  {/* <span>|  </span> */}
                  <span>
                    <a className="" href="#">
                      Terms & Conditions{" "}
                    </a>
                  </span>
                </div>
              </div>

              {/* </Composition> */}
            </Layout>
          </MobileAction>
        </>
      )}
    </Composition>
  );
};

export default LoginPage;
