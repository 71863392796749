import UserLabel from "@/Components/Common/Image/UserLabel";
import {
  DownloadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
} from "antd";
import { Upload, message } from "antd";
import Search from "antd/lib/input/Search";
import { Composition } from "atomic-layout";
import { useState } from "react";
// import { Importer, ImporterField } from "react-csv-importer";
import CSVReader from 'react-csv-reader';
// import "react-csv-importer/dist/index.css";
import InviteModal from "../inviteModal";
import "./ClassDetails.css";
import SampleCsv from "@/Assets/vidya_sample_students_roster.csv";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { addStudentsToClass, updateClass } from "@/Services/Class";
import { enable_setting_class_students } from "@/Services/Constants";
import Analytics from "@/Services/Analytics";
import {
  convertJsonToCSV,
  downloadCSVFile,
} from "@/Components/Common/CSVConverter";
import { getDisplayEmail } from "@/Services/User";

type Props = {
  _class: Class;
};

type StudentObj = {
  first_name: string;
  last_name: string;
  email: string;
};

const classStudentsArea = `
            AddStudents
            MyStudents
`;
const { Dragger } = Upload;

const ClassStudents = ({ _class }: Props) => {
  const { students_email } = _class;
  const inviteModalClass = _class;

  const [showInviteModal, setShowInviteModal] = useState(false);
  //const [inviteModalClass, setInviteModalClass] = useState(_class);
  const [toAddStudents, setToAddStudents] = useState<any[]>([]);
  const [toAddSingleStudent, setToAddSingleStudent] = useState<StudentObj>({
    first_name: "",
    last_name: "",
    email: "",
  });

  interface StudentData {
    email: string;
    first_name: string;
    last_name: string;
}

  const [singleStudentEmail, setSingleStudentEmail] = useState("");
  // const [singleStudentEmail, setSingleStudentEmail] = useState("");
  const { confirm } = Modal;

  const removeUser = (email: string) => {
    console.log("removing user ", email);
  };

  const isValidEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const addStudents = (obj: any[]) => {
    const newStudents: StudentObj[] = [];

    obj.forEach((o) => {
      const existingUserEmail = _class.students_email?.filter(
        (email) => email == o.email
      );
      if (!existingUserEmail || existingUserEmail.length == 0)
        newStudents.push(o);
    });

    // if (newStudents.filter(s => s.email != "").length == 0)
    // return; // in case of empty string

    if (
      newStudents.length === 0 ||
      newStudents.filter((s) => s.email != "").length == 0
    ) {
      message.warning(
        obj.length == 1
          ? obj[0].email + " is already added to this class"
          : "All the students were already part of the class"
      );
      return;
      // no new students
    }

    const validEmails = newStudents.filter((s) => isValidEmail(s.email));

    if (validEmails.length == 0) {
      message.warning("Enter valid email address");
      return;
    } else if (validEmails.length < newStudents.length) {
      message.warning(
        newStudents.length -
          validEmails.length +
          " invalid emails will be ignored"
      );
      return;
    }

    console.log("new students are - ", newStudents);

    confirm({
      title: "Consent",
      content: (
        <div>
          <span>
            {" "}
            I have read{" "}
            <a href="https://vdy.app/tnc" target={"_blank"}>
              Terms and Conditions{" "}
            </a>{" "}
            and confirm that I have consent to add these learners to Vidya on
            their behalf.
          </span>
        </div>
      ),

      okText: "I do",
      cancelText: "I don't",
      onOk: () => {
        addStudentsInClass(newStudents);
        setToAddSingleStudent({ email: "", first_name: "", last_name: "" });
        setToAddStudents([]);
      },
      onCancel: () =>
        notification.info({
          message: "Users not added because of lack of consent",
        }),
    });

    // call API to add these to backend.
  };

    const [isImporting, setIsImporting] = useState(false);

    const handleForce = (data: StudentData[]) => {
      processChunk(data);
      console.log("Import complete.");
  };

  const processChunk = (rows: StudentData[]) => {
      const mappedData = rows.map(({ email, first_name, last_name }) => ({
          email,
          firstName: first_name,
          lastName: last_name,
      }));

      toAddStudents.push(...mappedData);
      console.log("Processed rows:", toAddStudents);
  };
    const onStart = () => {
        console.log("Import started");
        setIsImporting(true);
        // Prepare your app for incoming data if needed
    };

    const onComplete = () => {
        console.log("Users added");
        addStudents(toAddStudents);
        setToAddStudents([]);
        setIsImporting(false);
    };

  const addStudentsInClass = (students: StudentObj[]) => {
    message.loading("Updating", 0);
    const emails = students.filter((s) => s.email != "").map((s) => s.email);
    if (emails.length > 0) {
      return addStudentsToClass(_class, students)
        .then(() => {
          console.log("updatedClass is ", _class);
          message.destroy();
          message.success(
            students.length == 1
              ? "Student added to the class"
              : students.length + " students added to the class"
          );
          setToAddSingleStudent({ email: "", first_name: "", last_name: "" });
        })
        .catch(() => {
          message.destroy();
          message.error("Something went wrong. Please try again in some time.");
        });
      // // const updatedStudents = [..._class.students_email, ...emails];
      // // const updatedClass = { ..._class, students_email: updatedStudents, students_count: updatedStudents.length };
      // updateClass(updatedClass)
      // .then(() => {
      //     console.log("updatedClass is ", updatedClass);
      //     message.destroy();
      //     message.success(students.length == 1 ? 'Student added to the class' : students.length + ' students added to the class');
      // }).catch(() => {
      //     message.destroy();
      //     message.error('Something went wrong. Please try again in some time.');
      // })
    }
  };

  const removeStudent = (student_email: string) => {};

  const removeStudentsInClass = (student_email: string) => {
    if (!enable_setting_class_students) {
      confirm({
        content:
          "Please reach out to support@vidya.us to remove a student from your class.",
      });
      return;
    }

    // const emails = students.filter(s => s.email);

    const index = _class.students_email.indexOf(student_email, 0);
    if (index > -1) {
      const updatedStudents = _class.students_email.filter(
        (s) => s != student_email
      );
      const updatedClass = {
        ..._class,
        students_email: updatedStudents,
        students_count: updatedStudents.length,
      };
      updateClass(updatedClass)
        .then(() => {
          console.log("updatedClass is ", updatedClass);
          message.destroy();
          console.log("updatedClass is ", updatedClass);
          message.success(student_email + " removed from the class");
        })
        .catch(() => {
          message.destroy();
          message.error("Something went wrong. Please try again in some time.");
        });
    }
  };

  const dataSource: any = [];
  students_email?.map((email) => {
    const userRow = {
      key: email,
      name: <UserLabel email={email} />,
      email: getDisplayEmail(email),
      action: (
        <Popconfirm
          title="Are you sure to remove this student?"
          onConfirm={() => removeStudentsInClass(email)}
          // onCancel={}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="text"
            // onClick={() => removeStudentsInClass(email)}
          >
            <MinusCircleOutlined style={{ color: "red" }} />
          </Button>
        </Popconfirm>
      ),
    };
    dataSource.push(userRow);
  });

  const columns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      // width: 50
    },
  ];

  const downloadStudentRoster = () => {
    console.log("download student roster for ", _class.id);
    const csvArray: {}[] = [];
    Analytics.track("Download Student Roster");
    if (!_class.students_users) {
      message.info("No students added to the class yet");
      return;
    }

    _class.students_users.forEach((user, index) => {
      var csvObj = {
        Email: user ? user.email : _class.students_email[index],
        "First Name": user ? user.first_name : _class.students_email[index],
        "Last Name": user ? user.last_name : _class.students_email[index],
      };
      if (user.email) csvArray.push(csvObj);
    });

    const csvStats = convertJsonToCSV(csvArray);
    console.log(csvStats);
    downloadCSVFile(
      csvStats,
      "Vidya Student Roster - " + _class.name + " - " + _class.description
    );
  };

  return (
    <Composition
      areas={classStudentsArea}
      alignItems="start"
      justifyItems="start"
      width="100%"
      className="student-details"
    >
      {({ AddStudents, MyStudents }) => (
        <>
          <AddStudents width="100%">
            <div className="max-w-3xl">
              <span className="text-xl">Add Students</span>
              <div>
                <span className="text-xs text-gray-500">
                  All of these students would be able to join your every session
                </span>
              </div>
              <Divider style={{ marginTop: 5 }} />
              {!enable_setting_class_students ? (
                <div className="md:w-2/3 ml-auto mr-auto w-full">
                  <InviteModal _class={inviteModalClass} />
                </div>
              ) : null}
              {enable_setting_class_students ? (
                <>
                  <span>
                    Add students to your class by sharing the invitation link.
                  </span>
                  <div className="text-center z10">
                    <InviteModal _class={inviteModalClass} />

                    {/* <Button
                                            type="default"
                                            size="small"
                                            className="text-xs mt-6 mb-6"
                                            onClick={(e) => setShowInviteModal(true)}
                                        > <span className="text-xs">
                                                Get Invitation Link
                                            </span>
                                        </Button>
                                        <Modal
                                            visible={showInviteModal}
                                            width={"550px"}
                                            onCancel={() => setShowInviteModal(false)}
                                            onOk={() => setShowInviteModal(false)}
                                            closable={true}
                                            footer={null}
                                            style={{
                                                padding: "10px",
                                            }}
                                            title={`Invite Students - ${inviteModalClass?.name}`}
                                        >
                                            {showInviteModal && inviteModalClass ? (
                                                <InviteModal _class={inviteModalClass} />
                                            ) : null}
                                        </Modal> */}
                  </div>
                  <Divider>or</Divider>
                  {/* <div className="text-center mt-6">or</div> */}
                  <div>
                    <span>Bulk-upload student roster via CSV.</span>

                    <div id="student-bulk-upload" className="mt-2 text-center">
                      
                    <div>
            <CSVReader
                onFileLoaded={(data) => {
                    onStart();
                    handleForce(data);
                    onComplete();
                }}
                cssClass="csv-reader-input"
                label="Select CSV file"
                onError={(err) => console.error(err)}
                accept=".csv"
            />
            {isImporting && <p>Importing in progress...</p>}
            <button onClick={onComplete} disabled={!isImporting}>
                Complete Import
            </button>
                    </div>

                      {/* <Importer
                        skipEmptyLines={true}
                        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                        restartable={true} // optional, lets user choose to upload another file when import is complete
                        onStart={(ImportInfo: any) => {
                          console.log(ImportInfo);
                          // optional, invoked when user has mapped columns and started import
                          // prepMyAppForIncomingData();
                        }}
                        processChunk={async (rows, { startIndex }) => {
                          // required, receives a list of parsed objects based on defined fields and user column mapping;
                          // may be called several times if file is large
                          // (if this callback returns a promise, the widget will wait for it before parsing more data)
                          // for (row of rows) {
                          //     await
                          //         // myAppMethod(row);
                          // }
                          toAddStudents.push(...rows);
                        }}
                        onComplete={({
                          file,
                          preview,
                          fields,
                          columnFields,
                        }) => {
                          console.log("users added");
                          addStudents(toAddStudents);
                          setToAddStudents([]);

                          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                          // showMyAppToastNotification();
                        }}
                      >
                        <ImporterField name="email" label="Email" />
                        <ImporterField name="first_name" label="First Name" />
                        <ImporterField name="last_name" label="Last Name" />
                      </Importer> */}
                    </div>
                    <div className="text-center">
                      {" "}
                      <Button type="link" href={SampleCsv} download={true}>
                        {" "}
                        Download Sample CSV
                      </Button>{" "}
                    </div>
                  </div>
                  <Divider>or</Divider>
                  {/* <div className="text-center mt-6 mb-4">or</div> */}
                  <div className="">
                    <Space wrap direction="horizontal">
                      <span className="mt-2"> Enter Manually :</span>
                      <span>
                        <Input.Group size="large">
                          <Row gutter={8}>
                            <Col span={5}>
                              <Input
                                placeholder="First Name"
                                value={toAddSingleStudent.first_name}
                                onChange={(e) =>
                                  setToAddSingleStudent({
                                    ...toAddSingleStudent,
                                    first_name: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col span={5}>
                              <Input
                                placeholder="Last Name"
                                value={toAddSingleStudent.last_name}
                                onChange={(e) =>
                                  setToAddSingleStudent({
                                    ...toAddSingleStudent,
                                    last_name: e.target.value,
                                  })
                                }
                              />
                            </Col>

                            <Col span={5}>
                              <Input
                                placeholder="Email"
                                value={toAddSingleStudent.email}
                                onPressEnter={() => {
                                  if (
                                    toAddSingleStudent.first_name &&
                                    toAddSingleStudent.last_name &&
                                    isValidEmail(toAddSingleStudent.email)
                                  ) {
                                    addStudents([toAddSingleStudent]);
                                    // setToAddSingleStudent({ email: "", first_name: "", last_name: "" })
                                  }
                                }}
                                onChange={(e) =>
                                  setToAddSingleStudent({
                                    ...toAddSingleStudent,
                                    email: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col span={5}>
                              <Button
                                type="primary"
                                className="mt-1 ml-2"
                                disabled={
                                  !toAddSingleStudent.first_name ||
                                  !toAddSingleStudent.last_name ||
                                  !isValidEmail(toAddSingleStudent.email)
                                }
                                onClick={() => {
                                  addStudents([toAddSingleStudent]);
                                }}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                        </Input.Group>
                        {/* <Search
                                                    className='searchbox student-email-add'
                                                    // value={singleStudentEmail}
                                                    // style={{ minWidth: 350 }}
                                                    allowClear
                                                    placeholder="Email address of new student"
                                                    enterButton={<PlusCircleOutlined />}
                                                    type="email"
                                                    size="middle"
                                                    onSearch={value => {
                                                        addStudents([{ email: value }])
                                                        // setSingleStudentEmail("");
                                                        // console.log(singleStudentEmail)
                                                    }}
                                                /> */}
                      </span>
                    </Space>
                  </div>
                </>
              ) : null}
            </div>
          </AddStudents>

          <MyStudents width="100%">
            {/* Gray divider */}
            {/* <div style={{
                            background: "#f0f2f5",
                            width: "calc(100vw - 60px)",
                            position: "absolute",
                            padding: 0,
                            zIndex: 100,
                            height: "25px",
                            margin: "3em -30px 0 -18px"
                        }}></div> */}
            <div className="max-w-3xl mt-24">
              <div className="w-full">
                <span className="text-xl">My Students</span>
                <span className="text-xl float-right mr-2">
                  <Button
                    title="Download Roster"
                    icon={<DownloadOutlined />}
                    onClick={() => downloadStudentRoster()}
                    type={"ghost"}
                  ></Button>
                </span>
              </div>
              <div>
                <span className="text-xs text-gray-500">
                  These are the enrolled students of this class
                </span>
              </div>
              <Divider style={{ marginTop: 5 }} />
              <div style={{ maxWidth: 800 }}>
                <ConfigProvider
                  renderEmpty={() => (
                    <Empty description="No students yet. Please add students by any of the above methods" />
                  )}
                >
                  <Table
                    showHeader={false}
                    scroll={{ x: true }}
                    pagination={{ hideOnSinglePage: true, pageSize: 150 }}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </ConfigProvider>
              </div>
            </div>
          </MyStudents>
        </>
      )}
    </Composition>
  );
};

export default ClassStudents;
